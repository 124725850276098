<template>
  <div :class="['g-content', isPC ? 'z-web' : 'z-phone',theme.primaryColor ? theme.primaryColor : '']">
    <VisualItem
      v-if="item.title1 && item.title1.value"
      :visualKeys="[item.title1.visualKeys, item.titleColor.visualKeys]"
      :style="item.titleColor && item.titleColor.value === 'black' ? 'color: #142033' : 'color : #fff'"
      @visual="handleVisual"
      class-name="u-title1"
    >
      {{ item.title1.value }}
    </VisualItem>
    <VisualItem
      v-if="item.title2 && item.title2.value"
      :visualKeys="[item.title2.visualKeys, item.titleColor.visualKeys]"
      :style="item.titleColor && item.titleColor.value === 'black' ? 'color: #142033' : 'color : #fff'"
      @visual="handleVisual"
      class-name="u-title2"
    >
      {{ item.title2.value }}
    </VisualItem>
    <VisualItem
      v-if="isPC && (item.subTitle && item.subTitle.value)"
      :visualKeys="[item.subTitle.visualKeys, item.subTitleColor.visualKeys]"
      :style="item.subTitleColor && item.subTitleColor.value === 'black' ? 'color: #444e66' : 'color : #fff'"
      @visual="handleVisual"
      class-name="u-subtitle"
    >
      {{ item.subTitle.value }}
    </VisualItem>
  </div>
</template>

<script>
import VisualItem from '~/components/common/VisualItem'
// import CMSLink from '~/components/common/Link'

export default {
  name: 'BannerContent',
  components: {
    VisualItem
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  methods: {
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss" scoped>

@import '~/assets/css/4mixin.css';

.g-content {
  &.z-phone {
    display: inline-block;

    .whiteBorder {
      background: transparent !important;
      border: 1px solid #fff !important;
      color: #fff !important;

      &:active {
        background: rgba(255, 255, 255, 0.2) !important;
      }
    }

    .blackBorder {
      background: transparent !important;
      border: 1px solid #000 !important;
      color: #000 !important;
    }
  }

  &.z-web {
    display: inline-block;

    .whiteBorder {
      background: transparent !important;
      border: 1px solid #fff !important;
      color: #fff !important;
    }

    .blackBorder {
      background: transparent !important;
      border: 1px solid #000 !important;
      color: #000 !important;
    }
  }
}
</style>
