<template>
  <VisualItem :id="id" :visualKeys="list.visualKeys" @visual="handleVisual" :class-name="['m-number-banner', isPC ? 'z-web' : 'z-phone', theme.primaryColor ? theme.primaryColor : '']" tip="编辑列表">
    <a-carousel
      v-if="list.value && list.value.length"
      :dots="list.value.length > 1 ? true : false"
      :autoplay-speed="5000"
      :autoplay="list.value.length > 1 && !cmsVisual ? true : false"
      class="m-number-banner"
      arrow="false"
      dots-class="m-dots"
    >
      <div
        v-for="(item, idx) in list.value"
        :class="`g-${(item.align && item.align.value) || 'left'}`"
        :key="idx"
      >
        <div v-if="isPC">
          <VisualItem
            v-if="!item.btn.value && item.link.value"
            :visualKeys="[item.img.visualKeys, item.img_2x.visualKeys, item.link.visualKeys]"
            @visual="handleVisual"
            class-name="u-banner-wrap"
          >
            <CMSLink
              v-if="item.link.value"
              :link="item.link.value"
            >
              <CMSImage
                v-if="(item.img && item.img.value) && (item.img_2x && item.img_2x.value)"
                :img="item.img ? item.img.value : ''"
                :img2x="item.img_2x ? item.img_2x.value : ''"
                class-name="u-banner"
              />
              <div :class="['u-content', 'g-c-bd', 'f-no-btn', item.align && item.align.value ? item.align.value : '']">
                <VisualItem
                  v-if="item.align"
                  :visualKeys="[item.align.visualKeys]"
                  @visual="handleVisual"
                >
                  <Content
                    :item="item"
                    @visual="handleVisual"
                  />
                </VisualItem>
                <Content
                  :isPC="isPC"
                  v-else
                  :item="item"
                  @visual="handleVisual"
                />
              </div>
            </CMSLink>
            <template v-else>
              <CMSImage
                v-if="(item.img && item.img.value) && (item.img_2x && item.img_2x.value)"
                :img="item.img ? item.img.value : ''"
                :img2x="item.img_2x ? item.img_2x.value : ''"
                class-name="u-banner"
              />
            </template>
          </VisualItem>
          <VisualItem
            v-else
            :visualKeys="[item.img.visualKeys, item.img_2x.visualKeys]"
            @visual="handleVisual"
            class-name="u-banner-wrap"
          >
            <CMSImage
              v-if="item.img && item.img.value && item.img_2x && item.img_2x.value"
              :img="item.img.value"
              :img2x="item.img_2x.value"
              class-name="u-banner"
            />
            <div :class="['u-content', 'g-c-bd', !((item.btn && item.btn.value) || (item.link && item.link.value)) ? 'f-no-btn' : '', item.align && item.align.value ? item.align.value : '']">
              <VisualItem
                v-if="item.align"
                :visualKeys="[item.align.visualKeys]"
                @visual="handleVisual"
              >
                <Content
                  :isPC="isPC"
                  :item="item"
                  @visual="handleVisual"
                />
              </VisualItem>
              <Content
                v-else
                :item="item"
                @visual="handleVisual"
              />
            </div>
          </VisualItem>
        </div>
        <div v-else>
          <VisualItem
            v-if="!item.btn.value && item.link.value"
            :visualKeys="[item.img_2x_h5.visualKeys, item.img_3x_h5.visualKeys, item.link.visualKeys]"
            @visual="handleVisual"
            class-name="u-banner-wrap"
          >
            <CMSLink
              v-if="item.link.value"
              :link="item.link.value"
            >
              <CMSImageBg
                v-if="(item.img_2x_h5 && item.img_2x_h5.value) && (item.img_3x_h5 && item.img_3x_h5.value)||(item.img && item.img.value) && (item.img_2x && item.img_2x.value)"
                :img="item.img_2x_h5.value ? item.img_2x_h5.value : itme.img.value"
                :img2x="item.img_3x_h5.value ? item.img_3x_h5.value : item.img_2x.value"
                class-name="u-banner"
              />
              <div :class="['u-content', 'g-c-bd', 'f-no-btn', item.align && item.align.value ? item.align.value : '']">
                <Content
                  :isPC="isPC"
                  :item="item"
                  @visual="handleVisual"
                />
              </div>
            </CMSLink>
            <template v-else>
              <CMSImageBg
                v-if="(item.img_2x_h5 && item.img_2x_h5.value) && (item.img_3x_h5 && item.img_3x_h5.value)||(item.img && item.img.value) && (item.img_2x && item.img_2x.value)"
                :img="item.img_2x_h5.value ? item.img_2x_h5.value : itme.img.value"
                :img2x="item.img_3x_h5.value ? item.img_3x_h5.value : item.img_2x.value"
                class-name="u-banner"
              />
            </template>
          </VisualItem>
          <VisualItem
            v-else
            :visualKeys="[item.img_2x_h5.visualKeys, item.img_3x_h5.visualKeys]"
            @visual="handleVisual"
            class-name="u-banner-wrap"
          >
            <CMSImageBg
              v-if="(item.img_2x_h5 && item.img_2x_h5.value) && (item.img_3x_h5 && item.img_3x_h5.value)||(item.img && item.img.value) && (item.img_2x && item.img_2x.value)"
              :img="item.img_2x_h5.value ? item.img_2x_h5.value : itme.img.value"
              :img2x="item.img_3x_h5.value ? item.img_3x_h5.value : item.img_2x.value"
              class-name="u-banner"
            />
            <div :class="['u-content', 'g-c-bd', !((item.btn && item.btn.value) || (item.link && item.link.value)) ? 'f-no-btn' : '', item.align && item.align.value ? item.align.value : '']">
              <Content
                :item="item"
                @visual="handleVisual"
                :isPC="isPC"
              />
            </div>
          </VisualItem>
        </div>
      </div>
    </a-carousel>
  </VisualItem>
</template>

<script>
import _ from 'lodash'
import config from './config.json'
import Content from './content'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import CMSImage from '~/components/common/Image'
import CMSImageBg from '~/components/common/ImageBg'
import CMSLink from '~/components/common/Link'
import VisualItem from '~/components/common/VisualItem'

const componentKey = 'NumberBanner'

export default {
  components: {
    CMSImage,
    CMSImageBg,
    CMSLink,
    VisualItem,
    Content
  },
  props: {
    id: {
      type: [String, Number],
      default: componentKey
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    cmsVisual: {
      type: Boolean,
      default: false
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data(content) {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(_.cloneDeep(config), componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    return {
      ...data
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  mounted() {
    console.log(this.isPC)
  },
  methods: {
    isBtnComplete(btn = {}) {
      return btn.link && btn.text
    },
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-number-banner {
  &.z-web {
    position: relative;
    width: 100%;
    height: 500px;
    user-select: none;
    overflow: hidden;

    &.m-visual-item {
      display: block;
    }

    .slick-slide {
      width: 100%;
      height: 500px;
      overflow: hidden;
    }

    .u-banner-wrap {
      position: relative;
      display: block;
      width: 100%;
      height: 500px;
      overflow: hidden;
      background: #e9eef9;

      .u-banner {
        position: absolute;
        display: block;
        width: 1920px;
        height: 500px;
        top: 0;
        left: 50%;
        margin-left: -960px;
        max-width: none;
      }

      .u-content {
        padding-top: 100px;
        color: #fff;
        text-align: left;

        &.f-no-btn {
          padding-top: 166px;
        }

        &.center {
          text-align: center;
        }

        &.right {
          text-align: right;
        }

        .u-title1,
        .u-title2 {
          font-size: 42px;
          line-height: 59px;
          margin-bottom: 0;
        }

        .u-subtitle {
          margin-top: 14px;
          margin-bottom: 0;
          font-size: 16px;
          line-height: 1.2;
        }
      }
    }

    .m-dots {
      bottom: 66px;

      li {
        margin: 0 4px;

        button {
          width: 30px;
          height: 3px;
          background-color: #fff;
          opacity: 1;
        }
      }

      li.slick-active button {
        background: $baseBlue;
      }
    }

    .m-header-login.f-transparent {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &.z-phone {
    position: relative;
    width: 100%;
    height: pxtorem(600);
    user-select: none;
    overflow: hidden;

    &.m-visual-item {
      display: block;
    }

    .slick-slide {
      width: 100%;
      height: pxtorem(600);
      overflow: hidden;
    }

    .u-banner-wrap {
      position: relative;
      display: block;
      width: 100%;
      height: pxtorem(600);
      overflow: hidden;

      .u-banner {
        width: 100%;
        position: absolute;
        display: block;
        height: pxtorem(600);
        top: 0;
        left: 50%;
        margin-left: -50%;
        max-width: none;
        background-size: cover;
      }

      .u-content {
        padding: pxtorem(80) pxtorem(60) 0;
        color: #fff;
        text-align: center;

        .u-title1 {
          font-size: pxtorem(52);
          line-height: pxtorem(72);
          margin-bottom: pxtorem(24);
          font-weight: bold;
        }

        .u-title2 {
          font-size: pxtorem(36);
          line-height: pxtorem(50);
        }

        .u-subtitle {
          margin-bottom: 0;
          font-size: pxtorem(26);
          line-height: 1.6;
        }
      }
    }

    .m-dots {
      bottom: pxtorem(44);

      li {
        margin: 0 pxtorem(16);

        button {
          width: pxtorem(52);
          height: pxtorem(3);
          background-color: #fff;
          opacity: 1;
        }
      }

      li.slick-active button {
        background: $baseBlue;
      }
    }
  }
}
</style>
